import * as React from "react"
import Seo from '../seo'
import Footer from '../components/footer'
import {useEffect} from "react";
import asyncAnalytics from "../components/analytics";

const content = [
  {
    title: <h4>Introdução</h4>,
    body: (
      <div>
        <p>Ao cadastrar-se para utilizar a plataforma da Dinerama (“<u>Plataforma</u>”), você (“<u>Usuário</u>”) se
          vincula aos presentes Termos e Condições Gerais de Uso (“<u>Termos de Uso</u>”), o qual gera direitos e
          obrigações ao Usuário e à Dinerama Ltda., pessoa jurídica de direito privado inscrita no CNPJ/ME sob o nº
          44.643.790/0001-07, com sede na Avenida Santo Amaro, 3.131 – cj. 1813, no bairro Brooklin Paulista da cidade
          de São Paulo/SP, CEP 04555-000 (“<u>Dinerama</u>”), entre si e a um perante o outro.</p>
        <p>Por essa razão, sugerimos uma leitura atenta e completa destes Termos de Uso, bem como da Política de
          Privacidade da Dinerama, em especial levando em conta que a compreensão das e a concordância às disposições de
          ambos os documentos é condição essencial ao cadastro e à utilização da Plataforma.</p>
        <div className="box">
          <p>NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES (PESSOAS COM IDADE INFERIOR A 18 ANOS)</p>
          <p>Os serviços disponibilizados pela Dinerama envolvem aspectos financeiros. Se você não possui, ainda, 18
            (dezoito) anos completos, por favor, certifique- se de fazer uso dos serviços com o monitoramento e
            acompanhamento de seu/sua responsável legal.</p>
        </div>
      </div>
    )
  },
  {
    title: <h4>1. O que é a Dinerama?</h4>,
    body: (
      <div>
        <p>1.1. A Dinerama é uma aplicação digital que tem como objetivo intermediar promoções entre empresas
          fornecedoras de bens de consumo (“<u>Parceiros</u>”) e os Usuários (consumidores finais de tais produtos),
          essencialmente através da Plataforma.</p>
        <p>1.2. Para tanto, oferece uma oportunidade para que o Usuário possa receber um “desconto especial” na compra
          de um produto ou serviço dos Parceiros. Esse “desconto especial” é concedido diretamente pela Dinerama por
          meio do reembolso de parte do valor gasto pelo Usuário nas compras efetuadas após a ativação das promoções na
          Plataforma – o “<i>cashback</i>”.</p>
        <p>1.3. Para saber mais sobre o <i>cashback</i> e o passo-a-passo para sua obtenção, favor consultar a seção 4
          adiante.</p>
      </div>
    )
  },
  {
    title: <h4>2. Como se cadastrar?</h4>,
    body: (
      <div>
        <p>2.1. A utilização da Plataforma é exclusiva para Usuários cadastrados.</p>
        <ol>
          <li>A Dinerama poderá não aprovar, limitar ou cancelar o acesso de Usuários que não tenham completado seu
            cadastro, na forma dos itens abaixo.
          </li>
          <li>A mesma providência poderá ser aplicável durante a utilização da Plataforma se for constatado que houve
            utilização de forma irregular ou fraudulenta, ou ainda qualquer tentativa ou violação destes Termos de Uso
            ou da legislação vigente pelo Usuário.
          </li>
          <li>A Dinerama poderá alterar os dados exigidos para cadastro, bem como os critérios de elegibilidade de
            acesso e utilização da Plataforma a qualquer momento e a seu critério, sendo toda e qualquer alteração
            comunicada aos Usuários e refletida nestes Termos de Uso, sendo de responsabilidade do Usuário tomar ciência
            das respectivas condições de uso antes da utilização de quaisquer conteúdo ou serviços.
          </li>
        </ol>
        <p>2.2. O cadastro na Plataforma deve ser iniciado por uma conta Google ou Apple (funcionalidade exclusiva para
          usuários de iPhone).</p>


        <p>2.3. Após o acesso inicial, para completar o cadastro, é necessário que o Usuário forneça alguns dados
          adicionais, a saber:</p>
        <ol>
          <li>Nome completo;</li>
          <li>CPF;</li>
          <li>Telefone celular;</li>
          <li>E-mail de comunicação (que pode ser diferente do e-mail de cadastro, utilizado na conta Google ou Apple
            vinculada); e
          </li>
          <li>Chave Pix para recebimento do saldo de <i>cashback</i>, na forma do item 4.5 abaixo.</li>
        </ol>

        <p>2.3.1. O fornecimento dos dados indicados nos itens (1) a (5) da Cláusula 2.2 acima é indispensável para o
          uso adequado e acesso às funcionalidades da Plataforma, sendo certo que, até que completo o cadastro, o
          Usuário poderá ter limitações de uso da Plataforma.</p>
        <p>2.3.2. O Usuário poderá, a qualquer tempo, ratificar as informações cadastrais acima mencionadas, à exceção
          do e-mail de cadastro, que será sempre o da conta Google ou Apple vinculada a seu acesso.</p>
        <p>2.3.3. Ao apresentar / retificar as informações cadastrais, o Usuário assume o compromisso de sua veracidade,
          bem como se compromete a apresentar, se solicitado pela Dinerama, os documentos comprobatórios das informações
          prestadas.</p>
      </div>
    ),
  },
  {
    title: <h4>3. Funcionalidades da Plataforma</h4>,
    body: (
      <div>
        <p>3.1. Quando do acesso, o Usuário terá acesso às seguintes funcionalidades da Plataforma:</p>
        <ol>
          <li>Ofertas: lista dos produtos disponibilizados pelos Parceiros em campanha(s) para o recebimento de cashback
            pelo Usuário;
          </li>
          <li>Destaques: conjunto de ofertas disponibilizadas pelos nossos Parceiros;</li>
          <li>Lista: ofertas ativadas pelo Usuário e, portanto, elegíveis para o cashback após a compra;</li>
          <li>Extrato: lista das operações de cashback efetivadas pelo Usuário, bem como do saldo disponível para
            resgate;
          </li>
          <li>Perfil: acesso aos dados de cadastro do Usuário, indicados no item 2.3 acima;</li>
          <li>Convide seus amigos: geração de link específico para cópia e uso ou de convite com mensagem pré-escrita
            para envio via WhatsApp para que o Usuário possa convidar outras pessoas a utilizar a Plataforma; e
          </li>
          <li>Me Ajuda: FAQ ou “perguntas e respostas” sobre a Plataforma, contendo orientações sobre os principais
            aspectos de seu uso e, em especial, sobre a operacionalização do cashback.
          </li>
        </ol>

        <p>3.2. A Dinerama poderá, de acordo com seus objetivos de negócio, a qualquer tempo, alterar, adequar ou
          remodelar o conteúdo, o layout, as funcionalidades e/ou ferramentas relacionadas à Plataforma, sua
          distribuição ou permissão de uso e acesso, desde que esteja em conformidade com a legislação brasileira, em
          especial a Lei nº 13.709 de 2018 (Lei de Proteção de Dados Pessoais) e a Lei Federal nº 12.965 de 2014 (Marco
          Civil da Internet).</p>
      </div>
    )
  },
  {
    title: <h4>4. Sobre o Cashback</h4>,
    body: (
      <div>
        <p>Uma vez que você tenha um cadastro validado e uma conta ativa na Dinerama,
          você estará elegível para receber o cashback – mas, para isso, deverá observar o procedimento abaixo:</p>
        <ol>
          <li>Escolher, dentre as ofertas disponibilizadas pelos Parceiros, as que deseja, selecionando a quantidade
            (observada a disponibilidade) e clicando em “Ativar Oferta”. Vale lembrar que as ofertas possuem prazo de
            expiração e podem se esgotar antes do prazo sem aviso prévio.
          </li>
          <li>Deslocar-se a qualquer estabelecimento comercial (que não precisa ser parceiro da Dinerama), observada a
            necessidade de emissão, pelo estabelecimento, de Nota Fiscal (que deve ser NFC-e, CF-e SAT ou NF-e);
          </li>
          <li>Adquirir o(s) produto(s) cuja(s) oferta(s) fora(m) ativada(s) antes da compra;</li>
          <li>Acessar a Plataforma e scanear o QR Code da Nota Fiscal de compra ou digitar a chave de acesso da mesma,
            lembrando que a Nota Fiscal é indispensável para a obtenção do cashback e que o envio da Nota Fiscal deve
            ocorrer dentro do período da promoção, como anunciada na Plataforma;
          </li>
          <li>O Usuário não ser uma das pessoas indicadas no item 4.7.</li>
        </ol>

        <p>4.2. Apenas será permitido ao Usuário solicitar o cashback pelo cadastro de, no máximo, 6 (seis) Notas
          Fiscais por semana, observado ainda o limite diário de 2 (duas) Notas Fiscais do mesmo estabelecimento.</p>
        <ol>
          <li>O Usuário deverá apenas utilizar, na Plataforma, Notas Fiscais de compras realizadas por si próprio. A
            Dinerama se reserva o direito de recusar a solicitação de cashback com base em Notas Fiscais que (i) contem
            com a identificação de um terceiro que não o Usuário, ou (ii) que, em conjunto, destoem do padrão de consumo
            residencial do cidadão médio brasileiro.
          </li>
        </ol>

        <p>4.3. São requisitos para que o cashback seja concedido e, portanto, creditado na conta do Usuário:</p>
        <ol>
          <li>Ter sido observado o procedimento previsto no item 4.1 acima;</li>
          <li>Terem sido observados os limites e condições previstos no item 4.2 acima;</li>
          <li>A Nota Fiscal referente à compra estar válida, ter sido registrada perante a Secretaria da Fazenda
            competente e ser compatível com a operação da Dinerama;
          </li>
          <li>Em alguns casos, com aviso prévio dentro do aplicativo, é obrigatório documento de identificação 
            na nota fiscal enviada.
          </li>
          <li>O lançamento da Nota Fiscal ter ocorrido dentro do prazo de vigência da campanha promovida pelo(s)
            Parceiro(s) cuja(s) oferta(s) fora(m) ativada(s) pelo Usuário;
          </li>
          <li>Não haver duplicidade de lançamento da Nota Fiscal na Plataforma, ou seja, não ter sido usada
            anteriormente por outro Usuário.
          </li>
          <li>O cashback será concedido apenas enquanto a oferta estiver disponível na Plataforma. Se a oferta for
            marcada como “Esgotado” no aplicativo antes do término do prazo de validade, o cashback não será válido
            para compras realizadas após essa expiração.
          </li>
          <li>A Dinerama se reserva o direito de limitar o valor do cashback concedido, especialmente em casos de
            cashback percentual. Existe um teto máximo para o valor do cashback, que é determinado com base no
            preço médio do produto informado pelo fornecedor.
          </li>
        </ol>

        <p>4.4. A Dinerama analisará a solicitação de cashback em até 15 (quinze) dias e, não havendo violação de
          qualquer dos requisitos do item 4.3 acima, o cashback referente às operações validadas será creditado na conta
          do Usuário na Plataforma.</p>
        <ol>
          <li>Caso seu cashback não tenha sido creditado no prazo de até 15 (quinze) dias, o Usuário poderá entrar em contato
            com a Dinerama para identificação do motivo da não concessão, que pode ter sido ocasionado por atraso do
            emissor da Nota Fiscal ou dificuldades sistêmicas ou operacionais não previstas. Nesse caso, a Dinerama
            tomará as medidas necessárias para tentar corrigir o problema, mas sem qualquer obrigação de resolução,
            tendo em vista que o problema pode ter sido gerado por terceiros fora do nosso controle.
          </li>
          <li>A qualquer tempo durante o período de análise da solicitação de cashback do Usuário, poderão ser indicados
            referidos valores no extrato do Usuário, e o serão de forma meramente ilustrativa, sem que seja gerada
            qualquer expectativa de recebimento ou caracterização de deferimento da solicitação pela Dinerama, de forma
            que, uma vez efetuada a análise (e caso seja negativa), referidos valores poderão ser excluídos da conta do
            Usuário sem que qualquer reparação, de qualquer natureza e a qualquer título, lhe seja devida.
          </li>
          <li>Caso sejam constatados indícios de irregularidade na obtenção do cashback, ainda que posteriormente à sua
            aprovação, incluindo mas não se limitando a violação das regras, má-fé ou fraude do Usuário, a Dinerama
            poderá suspender a conta do Usuário pelo período de até 45 (quarenta e cinco) dias para análise interna.
            Ocorrendo a suspensão, a Dinerama poderá solicitar informações complementares (v. item 4.6 abaixo) para
            liberação do Cashback. Sendo confirmada a irregularidade, o cashback poderá ser cancelado e a conta do
            Usuário poderá ser bloqueada.
          </li>
        </ol>

        <div className="box">
          <p>A Dinerama poderá solicitar ao Usuário, a qualquer tempo (e em especial quando da solicitação de resgate do
            cashback), o envio das Notas Fiscais cadastradas. Por isso, é importante mantê-las guardadas e em bom estado
            de conservação que permita a leitura, em especial, de sua chave de acesso e dos produtos adquiridos.</p>
        </div>

        <p>4.5. Para o resgate dos valores disponíveis na conta do Usuário na Plataforma, será necessário atender os
          seguintes requisitos:</p>
        <ol>
          <li>Acumular saldo mínimo de R$40,00 (quarenta reais);</li>
          <li>Ter cadastrada uma Chave Pix de titularidade do Usuário na conta junto à Plataforma; e</li>
          <li>Acionar a opção “Resgatar Cashback”, no menu “Extrato” da Plataforma, e seguir os procedimentos
            indicados.
          </li>
        </ol>

        <p>4.6. A Dinerama poderá solicitar informações complementares para liberação do resgate do cashback, como a
          comprovação da identidade do usuário (por meio do envio de uma foto própria – selfie – ao lado de um documento
          de identidade) ou os documentos de comprovação de compra (por meio do envio de uma cópia da Nota Fiscal) ou de
          pagamento (por meio do envio do extrato do cartão utilizado na compra, por exemplo).</p>

        <p>4.7. Fica vedada a obtenção de cashback pela Plataforma por quem tenha sido, no presente ou no passado, sócio, 
          administrador, funcionário, colaborador ou qualquer tipo de afiliado a um estabelecimento comercial que realize 
          a venda de produtos com campanhas ativas, pelos Parceiros, na Plataforma. A Dinerama se reserva o direito de 
          efetuar o cancelamento do cashback caso constate a condição do solicitante, em infração à presente disposição, 
          bem como de qualquer outra regra destes Termos de Uso.</p>
      </div>
    )
  },
  {
    title: <h4>5. Direitos e Obrigações da Dinerama e do Usuário</h4>,
    body: (
      <div>
        <p>5.1. Como o uso da Plataforma estabelece um vínculo entre o Usuário e a Dinerama, se estabelecem direitos e
          obrigações de um a outro, sobre os quais trataremos neste capítulo.</p>

        <p>5.2. O Usuário deve arcar com os meios necessários para utilizar a Plataforma, incluindo a posse de
          equipamento adequado para acesso, bem como a contratação de prestador de serviços de telecomunicações para
          fornecimento de conexão à internet, não sendo qualquer destes aspectos imputável ou atribuível à Dinerama.
          Além disso, se obriga o Usuário a:</p>
        <ol>
          <li>utilizar a Plataforma com retidão e de forma ética, cuidadosa e prudente, de acordo com a Lei e os
            presentes Termos de Uso, respeitando as condições que regem a sua utilização e finalidade;
          </li>
          <li>manter o sigilo dos dados de acesso à sua conta junto à Plataforma, em especial o seu login e senha,
            utilizando-os de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua
            senha ou qualquer mecanismo de autenticação com quem quer que seja, inclusive em plataformas ofertadas por
            terceiros;
          </li>
          <li>em caso de constatação de dados cadastrais divergentes / incompletos / equivocados, atualizá-los
            imediatamente por meio da Plataforma ou, quando não possível, informar a Dinerama para sua correção;
          </li>
          <li>reconhecer que todos os acessos e operações realizados após sua autenticação digital bem-sucedida (login
            na Plataforma) são interpretados como sendo de sua responsabilidade de forma incontestável, inclusive
            aqueles derivados de uso indevido ou divulgação de suas credenciais de acesso para terceiros;
          </li>
          <li>em caso de suspeita de que a confidencialidade de suas credenciais de acesso (login e senha) foi violada,
            proceder imediatamente com sua troca ou atualização, bem como, se o caso, informar à Dinerama sobre tal fato
            o quanto antes por meio dos canais de atendimento disponíveis;
          </li>
          <li>respeitar todos os direitos de propriedade intelectual de titularidade da Dinerama (como seu logotipo, o
            layout de sua plataforma, etc.), incluindo todos os direitos referentes a terceiros que porventura estejam,
            ou estiveram, de alguma forma ali disponíveis (como as marcas e elementos de propriedade industrial dos
            Parceiros);
          </li>
          <li>não acessar, tentar acessar, ou permitir que terceiros, por suas credenciais de acesso, acessem ou tentem
            acessar áreas de programação da Plataforma, seu banco de dados, códigos-fonte ou qualquer outro conjunto de
            dados inerentes à sua operação;
          </li>
          <li>não realizar, tentar realizar, ou permitir que terceiros, por suas credenciais de acesso, realizem ou
            tentem realizar qualquer espécie de engenharia reversa da Plataforma, nem traduzir, decompilar, copiar,
            modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de
            outra maneira, dispor inapropriadamente das funcionalidades da Plataforma;
          </li>
          <li>não utilizar softwares para automatização de acessos à Plataforma e/ou de mineração ou coleta automatizada
            de dados de qualquer tipo ou espécie além de outro aqui não tipificado que atue de modo similar;
          </li>
          <li>arcar com todas as obrigações de indenizar o sujeito lesado no caso de ocorrência de danos à Plataforma, à
            Dinerama ou a terceiros (incluindo, mas não se limitando a, outros usuários da Plataforma e Parceiros da
            Dinerama), assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo a exclusão
            da Dinerama, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando a Dinerama
            livre de prejuízos e ônus.
          </li>
        </ol>

        <p>5.3. A Plataforma e suas funcionalidades são apresentadas ao Usuário na maneira como estão disponíveis (as
          is), podendo passar por constantes aprimoramentos e atualizações, obrigando-se a Dinerama a:</p>
        <ol>
          <li>Preservar a sua funcionalidade, utilizando layout que facilita a usabilidade e navegabilidade, sempre que
            possível;
          </li>
          <li>Exibir as funcionalidades da Plataforma de maneira clara, completa, precisa e suficiente de modo que
            exista a exata percepção das operações realizadas e dos recursos disponíveis ao Usuário;
          </li>
          <li>Manter as informações disponíveis na Plataforma, em especial quanto às campanhas promovidas pelos
            Parceiros, tão precisas, atualizadas e completas quanto possível; e
          </li>
          <li>Proteger, por meio do estado da técnica disponível, os dados pessoais tratados em razão do uso, pelo
            Usuário, da Plataforma.
          </li>
        </ol>

        <p>5.4. A Dinerama empenha seus melhores esforços para a manutenção da disponibilidade contínua e permanente da
          Plataforma; no entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de
          manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, ou alheios à vontade
          da Dinerama, como falhas nos sistemas de comunicação e acesso à internet de seus fornecedores ou ataques
          cibernéticos.</p>
        <ol>
          <li>Se isso ocorrer, a Dinerama fará o que estiver ao seu alcance para restabelecer o acesso à e as
            funcionalidades da Plataforma o mais breve possível, dentro das limitações técnicas de seus serviços e dos
            serviços de terceiros (dos quais depende para manter a Plataforma online). Por essa razão, o Usuário fica
            ciente que não poderá pleitear indenização ou reparação de danos no caso de a Plataforma apresentar qualquer
            indisponibilidade, independente da motivação.
          </li>
          <li>Eventuais procedimentos de manutenção que acarretem a indisponibilidade da Plataforma por longos períodos
            serão informados por meio dos canais oficiais de comunicação da Dinerama e pelos meios de comunicação
            indicados pelo Usuário.
          </li>
        </ol>

        <p>5.5. Além do disposto acima e de demais previsões destes Termos de Uso, a Dinerama não será, em qualquer
          hipótese, responsável por:</p>
        <ol>
          <li>Quaisquer problemas, bugs, glitches ou funcionamentos indevidos que ocorrerem nos dispositivos e
            equipamentos do Usuário e que sejam resultantes, direta ou indiretamente, da instalação ou do uso regular da
            Plataforma;
          </li>
          <li>Por qualquer dano, direto ou indireto, ocasionado por eventos de terceiros, a exemplo, mas não se
            limitando a, ataque de hackers, falhas no sistema, no servidor ou na conexão à internet, inclusive por ações
            de softwares que possam, de algum modo, danificar ativos físicos ou lógicos ou a sua conexão em decorrência
            do acesso ou utilização da Plataforma;
          </li>
          <li>Pela navegação do Usuário por links para serviços de terceiros eventualmente disponibilizados na
            Plataforma;
          </li>
          <li>Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados
            disponibilizados em links para serviços de terceiros eventualmente disponibilizados na Plataforma, não
            sendo, portanto, responsável por prejuízos, perdas ou danos ocorridos pela visita a tais ambientes, cabendo
            ao Usuário verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou
            praticar algum ato;
          </li>
          <li>Pelas atitudes tomadas pelo Usuário com base, direta ou indiretamente, na Plataforma e, em especial, nas
            operações de cashback realizadas, o que inclui, mas não se limita, às previsões de aprovação da solicitação
            e de transferência dos valores disponíveis ao Usuário; e
          </li>
          <li>Por quaisquer consequências advindas da inexatidão das informações cadastrais prestadas pelo Usuário,
            incluindo, mas não se limitando, a eventuais não compensações de transferências do saldo disponível em razão
            de informações bancárias incorretas, hipótese em que nenhuma penalidade incorrerá à Dinerama.
          </li>
        </ol>
      </div>
    )
  },
  {
    title: <h4>6. Comunicação entre Dinerama e Usuário</h4>,
    body: (
      <div>
        <p>6.1. A Dinerama mantém à disposição do Usuário os seguintes canais de atendimento, disponíveis de segunda a
          sexta-feira, das 9h (nove horas da manhã) às 18h (seis horas da tarde):</p>
        <ol>
          <li>Fale Conosco: meajuda@dinerama.com.br</li>
          <li>Rede Social Oficial: @dineramaapp (instagram)</li>
        </ol>

        <p>6.2. Quando necessário comunicar-se com o Usuário, a Dinerama poderá utilizar todos os canais de comunicação
          fornecidos pelo Usuário, nas suas mais diversas modalidades, incluindo, mas não se limitando a envio de
          e-mail, ligação telefônica, envio de SMS ou qualquer outra forma de mensagem eletrônica instantânea.</p>
        <ol>
          <li>É exclusivamente do Usuário a responsabilidade pelo recebimento dos comunicados e notificações enviados
            pela Dinerama e ciência de seu teor, pelo que indispensável que os dados fornecidos no cadastro sejam
            corretos e precisos e, posteriormente, sejam mantidos sempre atualizados.
          </li>
          <li>É igualmente de responsabilidade do Usuário manter seus sistemas de anti-spam, filtros similares ou
            configurações de redirecionamento de mensagens ou, ainda, as permissões de notificações de seu aparelho
            móvel ajustados de modo que não interfiram no recebimento dos comunicados e materiais enviados pela
            Dinerama, não sendo aceitável nenhuma escusa de falta de ciência sobre e-mail, mensagem eletrônica ou
            notificação em virtude dos recursos mencionados.
          </li>
        </ol>
      </div>
    )
  },
  {
    title: <h4>7. Privacidade e Proteção de Dados Pessoais</h4>,
    body: (
      <div>
        <p>A Dinerama possui documento próprio, denominado Política de Privacidade, que regula o tratamento dos dados
          pessoais dos titulares com os quais mantém relação, inclusive e em especial os fornecidos pelos Usuários por
          meio da Plataforma, sendo referido documento parte integrante e inseparável dos presentes Termos de Uso,
          podendo ser acessado por meio deste <i>link</i>: <a href="/politica-de-privacidade">Política de
            Privacidade</a></p>
      </div>
    )
  },
  {
    title: <h4>8. Direitos Autorais e Propriedade Intelectual</h4>,
    body: (
      <div>
        <p>8.1. Todos os textos, fotografias, projetos, imagens, vídeos, ilustrações, ícones, tecnologias, links e
          demais conteúdos audiovisuais ou sonoros, incluindo o software da Plataforma, desenhos gráficos,
          códigos-fonte, telas de navegação, banco de dados, rede e seus arquivos são de propriedade exclusiva da
          Dinerama ou de terceiro que tenha autorizado sua utilização na Plataforma, estando protegidos pelas leis e
          tratados internacionais, vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os
          infratores sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.279/96, 9.609/98 e
          9.610/98.</p>
        <ol>
          <li>Estão abrangidos no conceito acima tratado, ainda, a expressão “<span className="bold">DINERAMA</span>”,
            seu logotipo e sua marca mista (logotipo + expressão comercial), bem como palavras ou símbolos que se
            assemelham a estes, porém com grafia ou estilo diferenciado, como marcas, nomes empresariais e nomes de
            domínio, os quais são de propriedade da Dinerama e estão protegidos pelas leis e tratados internacionais de
            proteção à propriedade intelectual acima mencionados.
          </li>
        </ol>

        <p>8.2. Ao utilizar a Plataforma, o Usuário declara que irá respeitar todos os direitos de propriedade
          intelectual da Dinerama e/ou de terceiros (conforme aplicável), incluindo, mas não se limitando, aos programas
          de computador, direitos autorais e direitos de propriedade industrial sobre as marcas, patentes, nomes de
          domínio, denominação social e desenhos industriais, eventualmente depositados ou registrados em nome da
          Dinerama, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma
          forma, disponíveis na Platforma, sendo que a sua infração poderá ensejar reflexos legais, além de sujeitar-se
          ao pagamento de indenização por perdas e danos judicialmente apurados.</p>
      </div>
    )
  },
  {
    title: <h4>9. Disposições Gerais</h4>,
    body: (
      <div>
        <p>9.1. Estes Termos de Uso refletem a operacionalização da Plataforma e, como ela, estão sujeitos a constantes
          melhoria e aprimoramento. Assim, a Dinerama se reserva o direito de modificá-los a qualquer momento, conforme
          sua finalidade ou conveniência, bem como para adequação e conformidade legal à disposição de lei ou norma que
          tenha força jurídica equivalente. Em caso de modificação destes Termos de Uso, iremos informar o Usuário pelos
          meios disponibilizados; porém, cabe ao Usuário verificá-los sempre que utilizar a Plataforma.</p>
        <p>9.2. Estes Termos de Uso não criam qualquer outra modalidade de vínculo entre o Usuário e a Dinerama,
          inclusive, e sem limitação, sociedade, joint-venture, mandato, representação, parceria, consórcio, associação,
          formação de grupo econômico, vínculo empregatício ou similar.</p>
        <p>9.3. Qualquer cláusula ou condição destes Termos de Uso que, por qualquer razão, venha a ser reputada nula ou
          ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições destes Termos de Uso,
          as quais permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão.</p>
        <p>9.4. A tolerância da Dinerama em exigir ou exercer quaisquer direitos ou disposições dos presentes Termos de
          Uso não constituirá renúncia, podendo esta exercer regularmente o seu direito, dentro dos prazos legais.</p>
      </div>
    )
  },
]

export default function PrivacyPolicy(props) {
  useEffect(() => {
    asyncAnalytics(props)
  }, [props]);

  return (
    <div id="agreements-root">
      <Seo title="Dinerama"/>
      <div className="header">
        <a href="https://www.dinerama.com.br">
          <img src="/images/logo-name.svg" alt="dinerama"/>
        </a>
      </div>
      <div className="privacy-policy">
        <h2>Termos de uso</h2>
        <h4 className="update-date">Data da Última Atualização: 29 de Maio de 2024</h4>
        <div className="content">
          {content.map((c) => (
            <div>
              {c.title}
              {c.body}
            </div>
          ))
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}
